// This file includes polyfills needed by Angular and is loaded before the app.
// You can add your own extra polyfills to this file.
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/reflect';

import 'core-js/es/reflect';
import 'zone.js';

// If you need to support the browsers/features below, uncomment the import
// and run `npm install import-name-here';
// Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html

// Animations

// Date, currency, decimal and percent pipes
// Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
// import 'intl';

// NgClass on SVG elements

//Enable process.env to be present (still doesnt pull env vars from OS though)
var global = window;
(window as any).global = window;
var Buffer = [];
(window as any). process = {
	env: { DEBUG: undefined },
};

//Used for browsers with partially native support of custom elements
//import '@webcomponents/custom-elements/src/native-shim';

//Used for browsers without a native support of custom elements
//import '@webcomponents/custom-elements/custom-elements.min'